import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { MediaQuery } from '../styles'

const Wrap = styled.div`
  width: 200px;
  border-radius: 25%;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
`

const AppStoreIcon: React.FC = (props) => {
  return (
    <Wrap {...props}>
      <StaticImage
        placeholder="tracedSVG"
        style={{ width: '100%', height: '100%', borderRadius: '25%' }}
        src="../../static/images/app-icon-white-bg.png"
        alt="My School Files"
      />
    </Wrap>
  )
}

export default AppStoreIcon
