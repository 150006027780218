import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import AppStoreIcon from './AppStoreIcon'
import { Colors, H1, List, MediaQuery, Text } from '../styles'
import ButtonSignup from './ButtonSignup'
import DownloadButtons from './DownloadButtons'
import HomeDownloadButtons from './HomeDownloadButtons'

const Title = styled(H1)`
  /* color: #fff; */
  font-size: 2rem;
  /* margin: 30px 5px 0 5px; */
  text-transform: none;
  text-align: center;
  color: ${Colors.white};
  /* padding: 20px 15px; */

  @media ${MediaQuery.Medium} {
    /* margin: 40px 0; */
    /* padding: 0 80px; */
    font-size: 3.5rem;
  }
  @media ${MediaQuery.Large} {
    /* margin: 30px 0; */
    /* text-align: left; */
    /* padding: 0; */
    font-size: 3.5rem;
    padding: 0 0 40px 0;
  }
`

const CardWrap = styled.div`
  width: 100%;
  background: ${Colors.blue};
  display: flex;
  flex: 1;
  justify-content: column;
`

const SectionContainer = styled.div`
  max-width: 1920px;
  /* height: 500px; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Container = styled.div`
  max-width: 1920px;
  /* height: 500px; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  @media ${MediaQuery.Large} {
    flex-direction: row;
  }
  @media screen and (min-width: 1200px) and (max-width: 1800px) {
    /* display: none; */
    padding: 40px 0;
  }
`

const LeftSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${MediaQuery.Medium} {
    justify-content: flex-start;
  }
`

const RightSide = styled.div`
  display: flex;
  justify-content: flex-end;
  @media ${MediaQuery.Medium} {
    width: 60%;
  }
`

const ImageWrap = styled.div`
  width: 100%;
  height: auto;
  width: 100%;
`

const TopCopyWrap = styled.div`
  padding: 40px 0;
  text-align: center;
  @media ${MediaQuery.Medium} {
    padding: 100px 0 20px 0;
  }
`

const ImageComponent = (
  <ImageWrap>
    <StaticImage
      style={{ width: '100%', height: '100%' }}
      // src="../../static/images/three-phones.png"
      src="../../static/images/Remote_Learning-01.svg"
      alt="My School Files"
    />
  </ImageWrap>
)

const CopyWrap = styled.div`
  @media ${MediaQuery.Large} {
    padding: 0 40px;
  }
`

const ButtonsWrap = styled.div`
  @media ${MediaQuery.Small} {
    padding: 40px 0;
  }
  @media ${MediaQuery.Medium} {
    padding: 40px 0;
  }
  @media ${MediaQuery.Large} {
    padding: 40px 0 0 40px;
  }
`

const ListHeader = styled(Text)`
  color: ${Colors.white};
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  padding: 30px 0 10px 0;
  @media ${MediaQuery.Medium} {
    padding: 30px 0 10px 0;
    text-align: center;
    font-size: 2rem;
  }
  @media ${MediaQuery.Large} {
    padding: 0;
    text-align: left;
    font-size: 2rem;
  }
`

const SomethingBetterCard: React.FC = () => {
  return (
    <CardWrap>
      <SectionContainer>
        <TopCopyWrap>
          <Title>...for something way BETTER!</Title>
        </TopCopyWrap>
        <Container>
          <LeftSide>
            <CopyWrap>
              <ListHeader>With My School Files</ListHeader>
              <List style={{ color: Colors.white }}>
                <li>
                  Capture school items and upload pictures of what you did.
                </li>
                <li>
                  Add school days, co-ops, and field trips to the calendar.
                </li>
                <li>
                  And that's it! We organize it and automatically generate a
                  beautiful and professional portfolio for you.
                </li>
              </List>
            </CopyWrap>
            <ButtonsWrap>
              <HomeDownloadButtons colorScheme="white" />
            </ButtonsWrap>
          </LeftSide>
          <RightSide>{ImageComponent}</RightSide>
        </Container>
      </SectionContainer>
    </CardWrap>
  )
}

export default SomethingBetterCard
