import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { CgArrowLongRight } from 'react-icons/cg'

import Header from './header'
import './layout.css'
import AppHeader from './AppHeader'
import { Colors, GlobalStyle, MediaQuery } from '../styles'
import Footer from './Footer'
import styled from 'styled-components'

const Banner = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: solid 10px #994846;
  background-color: ${Colors.pink};
  p {
    font-size: 1rem;
    text-align: center;
    font-weight: bold;
    color: #fff;
    @media ${MediaQuery.Medium} {
      font-size: 1.25rem;
    }
  }
`

const FreeTrialCard: React.FC = ({ children }) => {
  return (
    <Banner href="/download">
      <p style={{ paddingRight: '10px' }}>
        Ready to give it a try? No credit card required. Install Now
      </p>
      <CgArrowLongRight color="#fff" size={30} />
    </Banner>
  )
}

export default FreeTrialCard
