import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'
import HeroCard from '../components/HeroCard'
import CalendarCard from '../components/CalendarCard'
import SchoolItemsCard from '../components/SchoolItemsCard'
import ShareCard from '../components/ShareCard'
import HeroCardLearnMoreHome from '../components/HeroCardLearnMoreHome'
import FadeInViewport from '../components/FadeInViewport'
import PricingHeroCard from '../components/PricingHeroCard'
import ShareHowToCard from '../components/ShareHowToCard'
import HowItWorksMainCard from '../components/HowItWorksMainCard'
import PDFCard from '../components/PDFCard'
import DitchTheClutterCard from '../components/DitchTheClutterCard'
import SomethingBetterCard from '../components/SomethingBetterCard'
import FreeTrialCard from '../components/FreeTrialCard'
import CategorizeCard from '../components/CategorizeCard'
import CalendarHowToCard from '../components/CalendarHowToCard'
import AddItemCard from '../components/AddItemCard'
import StudentHomeCard from '../components/StudentHomeCard'

const IndexPage = () => {
  useEffect(() => {
    if (window === undefined) return
    if (window.gtag === undefined) return
    window.gtag('event', 'conversion', {
      send_to: 'AW-689052345/FOjDCNuMxPcCELm1yMgC'
    })
  }, [])
  return (
    <Layout>
      <Seo
        title="Homeschooling just got easier!"
        url="https://myschoolfiles.com"
      />
      <HowItWorksMainCard />
      <FreeTrialCard />
      <DitchTheClutterCard />
      <SomethingBetterCard />

      <FadeInViewport>
        <ShareHowToCard bgColor="#efefef" />
      </FadeInViewport>

      <FadeInViewport>
        <PDFCard />
      </FadeInViewport>

      <FadeInViewport>
        <PricingHeroCard />
      </FadeInViewport>
    </Layout>
  )
}

export default IndexPage
