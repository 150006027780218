import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import AppStoreIcon from './AppStoreIcon'
import {
  blueGradientMixin,
  Colors,
  Column,
  H1,
  H2,
  MediaQuery,
  Row,
  Text
} from '../styles'
import PageContainer from './PageContainer'
import ButtonLearnMore from './ButtonLearnMore'
import { FaHeart } from 'react-icons/fa'
import ButtonSignup from './ButtonSignup'
import FullWidthImageTextCard from './FullWidthImageTextCard'
import DownloadButtons from './DownloadButtons'
import ButtonFreeTrial from './ButtonFreeTrial'
import HomeDownloadButtons from './HomeDownloadButtons'

const Title = styled(H1)`
  /* color: #fff; */
  font-size: 2rem;
  /* margin: 30px 5px 0 5px; */
  text-transform: none;
  text-align: center;
  color: #fff;
  padding: 20px 15px;

  @media ${MediaQuery.Medium} {
    margin: 40px 0;
    padding: 0 80px;
    font-size: 3.5rem;
  }
  @media ${MediaQuery.Large} {
    margin: 30px 0;
    text-align: left;
    padding: 0;
    font-size: 3.5rem;
  }
`

const AppDescription2 = styled(Text)`
  color: #fff;
  display: inline-flex;
  align-items: center;
  margin: 15px 0;
  padding: 0 20px;
  text-transform: none;
  text-align: justify;

  @media ${MediaQuery.Medium} {
    margin: 0 0 40px 0;
    padding: 0 80px;
  }

  @media ${MediaQuery.Large} {
    text-align: left;
    max-width: 800px;
    padding: 0;
    margin: 0;
  }
`

const StyledAppIcon = styled(AppStoreIcon)`
  @media ${MediaQuery.Small} {
    display: none;
  }
  @media ${MediaQuery.Medium} {
    display: none;
  }
  @media ${MediaQuery.Large} {
    display: block;
  }
`

const CTA = styled(ButtonSignup)`
  margin: 40px 0;
  width: 90%;
  margin: 0 auto;
  @media ${MediaQuery.Medium} {
    max-width: 580px;
    margin: 0 auto;
  }
  @media ${MediaQuery.Large} {
    max-width: 580px;
    margin: 40px 0;
    /* margin: 0 auto; */
  }
`

const CardWrap = styled.div`
  width: 100%;
  background: ${Colors.blue};
  display: flex;
  flex: 1;
  justify-content: column;
`

const Container = styled.div`
  max-width: 1920px;
  /* height: 500px; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: column-reverse;
  @media ${MediaQuery.Medium} {
    padding-top: 15px;
  }
  @media ${MediaQuery.Large} {
    flex-direction: row;
  }

  @media screen and (min-width: 1200px) and (max-width: 1800px) {
    /* display: none; */
    padding: 40px 0;
  }

  /* flex: 1; */
`

const LeftSide = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const RightSide = styled.div`
  display: flex;
  justify-content: center;
  @media ${MediaQuery.Medium} {
    justify-content: flex-end;
  }
`

const ImageWrap = styled.div`
  overflow: hidden;
  height: auto;
  width: 100%;
`

const ImageComponent = (
  <ImageWrap>
    <StaticImage
      style={{ width: '100%', height: '100%' }}
      // src="../../static/images/three-phones.png"
      src="../../static/images/Reading_02-01.svg"
      alt="My School Files"
    />
  </ImageWrap>
)

const CopyWrap = styled.div`
  @media ${MediaQuery.Large} {
    padding: 0 40px;
  }
`

const ButtonsWrap = styled.div`
  @media ${MediaQuery.Small} {
    padding: 40px 0;
  }
  @media ${MediaQuery.Medium} {
    padding: 40px 0;
  }
  @media ${MediaQuery.Large} {
    padding: 40px 0 0 40px;
  }
`

const HowItWorksMainCard: React.FC = () => {
  return (
    <CardWrap>
      <Container>
        <LeftSide>
          <CopyWrap>
            <Title>Homeschool Record Keeping Made Simple</Title>
            <AppDescription2>
              Homeschoolers like you use My School Files to track their
              student's progress and we automatically build a professional
              portfolio that can be securely shared with family, friends, and
              evaluators and downloaded as a PDF.
            </AppDescription2>
          </CopyWrap>
          <ButtonsWrap>
            <HomeDownloadButtons colorScheme="white" />
          </ButtonsWrap>
        </LeftSide>
        <RightSide>{ImageComponent}</RightSide>
      </Container>
    </CardWrap>
  )
}

export default HowItWorksMainCard
