import React from 'react'
import styled from 'styled-components'
import ButtonDownloadApp, { ColorScheme } from './ButtonDownloadApp'
import { Colors, MediaQuery } from '../styles'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin: 40px 0; */
  height: 160px;
  text-align: center;
  @media ${MediaQuery.Medium} {
    /* padding: 40px 0; */
    height: auto;
    flex-direction: row;
    justify-content: space-evenly;
  }
  @media ${MediaQuery.Large} {
    /* padding: 40px 0; */
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
    [data-type='android'] {
      margin-left: 20px;
    }
  }
`

const LinkWrap = styled.div`
  display: inline-block;
  margin: 20px 0;
`

interface Props {
  children?: React.ReactNode
  colorScheme?: ColorScheme
}

const HomeDownloadButtons: React.FC<Props> = (props) => {
  return (
    <Wrap {...props}>
      <ButtonDownloadApp colorScheme={props.colorScheme} type="ios" />
      <ButtonDownloadApp colorScheme={props.colorScheme} type="android" />
    </Wrap>
  )
}

export default HomeDownloadButtons
